.schedules table,
th,
td {
  border: 3px solid black;
  /* border-collapse: collapse; */
}

.schedules th,
td {
  padding: 15px;
  text-align: left;
}
.schedules th {
  background-color: rgb(137, 128, 240);
}
.schedules table caption {
  font-size: large;
  font-weight: bolder;
}
#resume a {
  color: black;
  text-decoration: none;
}
#resume a:visited {
  color:black;
}
  .schedules a:hover {
  color: burlywood;
}
.schedules table td a:active {
  color: white;
}

.popup-modal {
  background-color: gray;
  padding: 20px;
  color: black;
}
